import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from "../firbase";
import {
    collection,
    getDocs,
    addDoc,
    setDoc,
    updateDoc,
    deleteDoc,
    doc,
} from "firebase/firestore";
import styles from "./slot.module.css"

function Slot() {
    const navigate = useNavigate();
    const { userId } = useParams();
    console.log("slot id", userId);
    const usersCollectionRef = collection(db, "users");
    const slots = ['9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM']
    const [dateArray, setDateArray] = useState([]);
    const [userDate, setUserDate] = useState('');
    // const [today, setToday] = useState();
    const [usersData, setUsersData] = useState();
    const [id, setId] = useState();
    const [userTime, setUserTime] = useState();

    const UpdateUser = async () => {
        console.log("id:", id);
        const userDoc = doc(db, "users", id);
        const newFields = { selectDate: userDate, selectTime: userTime };
        await updateDoc(userDoc, newFields)
        navigate(`/confirm/${id}`)
    }

    const selectItem = (item) => {
        setUserTime(item);
    }


    useEffect(() => {
        const today = new Date();
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        const formatToday = today.toLocaleDateString("en-US", options);
        console.log("formatToday", formatToday);
        const tomorrow = new Date().setDate(today.getDate() + 1);
        const formatTomorrow = new Date(tomorrow).toLocaleDateString("en-US", options)
        console.log("tomorrow", new Date(tomorrow));
        let tempArray = [];
        tempArray.push(formatToday)
        tempArray.push(formatTomorrow)
        setDateArray(tempArray);
        console.log("Array of time slots", dateArray);

        const getUsers = async () => {
            console.log("users");
            const data = await getDocs(usersCollectionRef);
            console.log(data);
            setUsersData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
            const tempData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            console.log("tempData", tempData);
            const filterData = tempData.filter((item) => item.phoneNumber === userId)
            console.log("filter data:", filterData);
            var sid = filterData[0].id;
            setId(filterData[0].id);
            console.log("id:", sid);
        };
            getUsers();
    }, [])

    // console.log("All Users",usersData)
    const checkDisable = (time) => {
        if (userDate) {
            if (usersData) {

                let userSelectedData = {};
                usersData.map((each) => {
                    let count = usersData.filter((item) =>
                        item.selectDate == each.selectDate && item.selectTime == each.selectTime)
                    userSelectedData[each.selectDate] = { [each.selectTime]: count.length };
                }
                );
                console.log("userSelectData : ", userSelectedData);
                if (userDate in userSelectedData && time in userSelectedData[userDate] && userSelectedData[userDate][time] >= 10) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        else return true;
    }


    return (
        <div className={`'container ${styles['book-slot']}`}>
            <div className='card p-5 '>
                <div>
                    <i className="bi bi-calendar"></i><span className='ms-3'>Select a date</span>
                </div>
                <div className="container">
                    <div className="row">
                        {dateArray?.map((eachDate) => (
                            <button type="button" className="btn btn-outline-primary mt-3 mb-3 w-25 me-3" style={{ backgroundColor: eachDate == userDate ? 'blue' : '', color: 'black' }} onClick={() => {
                                setUserDate(eachDate)
                                setUserTime('')
                            }}>{eachDate}</button>
                        ))
                        }
                    </div>
                </div>
                <div>
                    <i className="bi bi-clock"></i><span className="ms-3">Select a time for</span>
                    <div className="container">
                        <div className="row">
                            {slots?.map((item) => (
                                <button type="button" disabled={checkDisable(item)} className='btn btn-outline-primary w-25 me-3 mt-3' style={{ backgroundColor: item == userTime ? 'blue' : '', color: 'black' }} onClick={() => selectItem(item)}>{item}</button>
                            ))}
                        </div>
                    </div>
                    <p className={`${styles['long-txt']} para-text mt-3`}>Due to high demand,it's difficult to reschedule a trial Session.Please pick your slot carefully</p>
                </div>
                <button type="button" className="btn btn-primary confirm-btn" onClick={ UpdateUser}>CONFIRM SLOT</button>
            </div>
        </div>
    )
}
export default Slot;