import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Website from '../src/components/website';
import Login from '../src/components/login';
import Slot from '../src/components/slot';
import ConfirmPage from './components/confirmPage';
import TermsConditions from './components/termsconditions';
import { UserAuthContextProvider } from "./context/UserAuthContext";

function App() {
  return (
    <BrowserRouter>
    <UserAuthContextProvider>

    <Routes>
        <Route path="/" element={<Website/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/slot/:userId" element={<Slot/>} />
        <Route path="/confirm/:id" element={<ConfirmPage/>} />
        <Route path="/terms" element={<TermsConditions/>} />
      </Routes>
    </UserAuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
