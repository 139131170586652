// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { getFirestore} from '@firebase/firestore';

    const firebaseConfig = {
      apiKey: "AIzaSyCb01j39uAs4VWLqwkaRIntWEOBwHp6A_Y",
      authDomain: "studoc-d32c0.firebaseapp.com",
      projectId: "studoc-d32c0",
      storageBucket: "studoc-d32c0.appspot.com",
      messagingSenderId: "459478957829",
      appId: "1:459478957829:web:8cc2dad72f80bdcd75016c"
      };
      
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      export const auth = getAuth(app);
      export const db=getFirestore(app);

export default app;