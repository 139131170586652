import React, { useState } from "react";
import Navbar from "../components/navbar";
import "./style.css";
import { Link } from "react-router-dom";
import styles from "./website.module.css";
import { db } from "../firbase";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

function Website() {
  const usersCollectionRef = collection(db, "users");
  const educationType = {
    school: [
      "class 1",
      "class 2",
      "class 3",
      "class 4",
      "class 5",
      "class 6",
      "class 7",
      "class 8",
      "class 9",
      "class 10",
      "class 11",
      "class 12",
    ],
    college: ["B Tech", "B Pharm"],
  };
  const [select, setSelect] = useState(educationType["school"]);
  const [selectClass, setSelectClass] = useState();
  const handleClass = (type) => {
    setSelect(educationType[type]);
  };

  console.log("class:",selectClass);
  const userData=localStorage.getItem("user");
  console.log("userData:",userData);

  return (
    <>
      <Navbar></Navbar>
      <div className="main-container">
        <div className="container text-center">
          <div className={`${styles["index-page"]} row`}>
            <div className={`${styles["education-block"]} col-6`}>
              <h1 className="main-heading">
                <span style={{color:"blue"}}>Your Own Personal Mentor</span><br></br>
                 Live, Online Sessions
              </h1>
              <div className={`card ${styles["ed-card"]}`}>
                <h3>Book your FREE Session</h3>
                <div className="row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => handleClass("school")}
                    >
                      School
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => handleClass("college")}
                    >
                      College
                    </button>
                  </div>
                </div>
                <div className="container mt-3">
                    <p>Select your child's grade in school</p>
                  <div className="row">
                    <div className="col">
                    {select?.map((item) => (
                        <button
                          style={{width:'5.5rem'}}
                          type="button"
                          className="btn btn-outline-primary me-1 mt-1"
                          onClick={()=>{
                            localStorage.setItem('user',item)
                            setSelectClass(item)
                          }}
                        >
                          {item}
                        </button>
                    ))}
                    </div>
                  </div>
                </div>
                <Link to="/login">
                  <button className="btn btn-primary mt-3" type="submit">
                    Schedule a FREE Session
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-6">
              <img
                className={`${styles["image"]}`}
                style={{ objectFit: "contain" }}
                src="https://www.evidencebasedmentoring.org/wp-content/uploads/2021/02/coach-vs-mentor.jpg"
                alt="student"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Website;
