import React from 'react';
import {Link} from 'react-router-dom';
import styles from './navbar.module.css'
function Navbar(){
  const joinMeeting=()=>{
    window.open(
      "https://meet.google.com/qry-gwik-ory", // when I open this link in browser it work
       '_blank' 
     ); 
  }
return(
    <>
    <nav className="navbar bg-light fixed-top">
  <div className="container-fluid">
    <Link to="/">
      <h2 className='main-title'>
        <img style={{width:"70px",height:"70px"}} src='../../images/weblogo.png' alt="logo" />
        GOOGLIFY<span className='sub-title'>A Live Learning Partner</span>
      </h2>
      </Link>
    <form className={`${styles['nav-bar']} d-flex`} role="search">
    <Link to='/login'>
      <button  className="btn btn-primary" onClick={()=>{joinMeeting()}}>Join a Session</button>
    </Link>
    <Link to='/login'>
      <button className="btn btn-primary ms-3" type="submit">Book A FREE Session</button>
    </Link>
    </form>

  </div>
</nav>
    </>
)
}
export default Navbar;