import React, { useState, useEffect} from 'react';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import { useUserAuth } from "../context/UserAuthContext";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firbase";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import styles from "./login.module.css"

function Login() {
    const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  const { setUpRecaptha } = useUserAuth();
  const [users, setUsers] = useState([]);
  const usersCollectionRef = collection(db, "users");
  const [userId,setUserId]= useState('');
  const navigate = useNavigate();

  const userData=localStorage.getItem("user");
  console.log("userData:",userData);

  const isExistingUser=()=>{
    return users.filter((item)=>item.phoneNumber==number)
  }
  const createUser =  () => {
    let userExists=isExistingUser();
    console.log(userExists);
    if(userExists.length){
      setUserId(userExists[0].id);
    }
    else{
      console.log("else");
     addDoc(usersCollectionRef, { class:userData,phoneNumber:number, selectDate:'',selectTime:'',parentEmail:'',parentName:'',parentNumber:''});
      // then((user)=>console.log("user created successfully",user))
      //  const data =  getDocs(usersCollectionRef);
      // console.log(data);
      // const details=data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      //  setUserId(details[0].id);
       console.log("all users:tempData",users);
      //  setUserId([0].id);
    }
    navigate(`/slot/${number}`);
  };

  const getOtp = async (e) => {
    e.preventDefault();
    console.log(number);
    setError("");
    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");
    try {
      const response = await setUpRecaptha(number);
      setResult(response);
      console.log(response);
      setFlag(true);
    } catch (err) {
      setError(err.message);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null) return;
    try {
      await result.confirm(otp);
      createUser();
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(usersCollectionRef);
      // console.log(data);
      setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getUsers();
  }, []);

  console.log('Users data:',users);

    return (
        <div className={`container  ${styles['login-page']}`}>
            <div className="card p-5">
                <h1 className='login-title'>Googlify</h1>
                <h3>Book Your Free Session</h3>
                <p>Limited Spots Left!</p>
                <form onSubmit={getOtp} style={{ display: !flag ? "block" : "none" }}>

                <PhoneInput
                    class="form-control"
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={number}
                    onChange={setNumber} />
                <div id="recaptcha-container"></div>
                <div className='d-flex flex-row mt-2'>
                  <input type='checkbox' className='me-2'/>I agree with 
                  <span className='terms-heading ms-2'>
                    <Link to='/terms'>Terms and Conditions</Link>
                  </span>
                </div>
                <p className='para-text mt-3'>By clicking the 'Schedule a FREE Session' button you agree to Googlify <a>Terms & Conditions</a>, acknowledge that you have read the Privacy Policy and to receive calls and text from, or on behalf of, Googlify at the number you provided, including for marketing purposes and by autodialer. Email to opt-out. Your consent is not a condition of any purchase.</p>
                <button type="submit" className={`${styles['sign-in']} btn btn-primary mt-3 `} >Sign In</button>

               
                </form>
                <form  style={{ display: flag ? "block" : "none" }}>
                   <input type="otp" 
                   placeholder="Enter OTP"
                   onChange={(e) => setOtp(e.target.value)}
                   /> 
                   <button type="submit" className='btn btn-primary' onClick={verifyOtp}>Verify</button>
                </form>
            </div>
        </div>
    )
}
export default Login;