import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firbase";
import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import styles from "./confirmPage.module.css";
import ParentDetail from "../components/parentdetails";

function ConfirmPage() {
  const { id } = useParams();
  console.log("id: " + id);
  const [users, setUsers] = useState();
  const usersCollectionRef = collection(db, "users");

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(usersCollectionRef);
      console.log(data);
      const tempData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      const loginUsers = tempData.filter((user) => {
        return user.id == id;
      });
      console.log(loginUsers);
      setUsers(loginUsers);
    };
    getUsers();
  }, []);
  // console.log("login users", users);

  return (
    <div className={`container ${styles["confirm-page"]}`}>
      <button className="btn btn-success p-3 w-100">
        Your Mentor is Awaiting For You
      </button>
      <ParentDetail userId={id}/>
      <div className="card p-5">
        <div className="row">
          <div className={`${styles["heading"]} col-6`}>
            <div className="card p-3">
              <h3>Your Innovative Learning Starts Now</h3>
              <div className="row">
                {users?.map((each) => (
                  <div className="col">
                    <span className="me-3">{each.selectDate}</span>
                    <span>{each.selectTime}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="col">
              <button className={`btn btn-light ${styles["join-btn"]}`}>
                JOIN YOUR SESSION
              </button>
              <button className={`${styles["setup-btn"]} btn btn-warning `}>
                SETUP YOUR LAPTOP/PC NOW
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ConfirmPage;
