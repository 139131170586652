import React, { useEffect, useState, useParams } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import styles from "./parentdetails.module.css";
import { db } from "../firbase";
import {
    collection,
    getDocs,
    addDoc,
    setDoc,
    updateDoc,
    deleteDoc,
    doc,
} from "firebase/firestore";

function MyVerticallyCenteredModal(props) {
 
  const usersCollectionRef = collection(db, "users");
  const [parentEmail,setParentEmail]=useState('');
  const [parentName,setParentName]=useState('');
  const [parentNumber,setParentNumber]=useState('');
  const [usersData, setUsersData] = useState();
  const [id, setId] = useState();
  const [state,setState] = useState({});
  const [nameError,setNameError]=useState('');
  const [numberError,setNumberError]=useState('');
  const [emailValidate,setEmailValidate]=useState('');
  const [isValid, setValid] = useState(false);

  const updateDetails= async()=>{
    const userDoc = doc(db, "users", props.userId);
        console.log(userDoc);
        const newFields = { parentEmail:parentEmail,parentName:parentName,parentNumber:parentNumber };
        await updateDoc(userDoc, newFields).then( doc =>{
            console.log("updated user",doc);
            props.onHide();
        }).catch(error=>{console.log(error);
        });
  }
  const validateEmail = (email) => {
    let regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(regexEmail)) {
      // setEmailValidate("Valid Email")
      setParentEmail(email);
      console.log("email is valid",email);
      return true;
    } else {
      setEmailValidate("Please enter a valid email")
      return false;
    }
  }
  const handleChange=(event)=>{
    console.log(event);
    if(event.target.name=='parentemail'){
      validateEmail(event.target.value)
    }
    if(event.target.name=='parentname'){
      if(event.target.value==''||event.target.value==null)
      setNameError('please enter parents name')
      else{
        setParentName(event.target.value);
      }
    }
    if(event.target.name=='parentnumber'){
      if(event.target.value==''||event.target.value=='null'){
        setNumberError('please enter parents name');
      }
      else{
        setParentNumber(event.target.value);
      }
    }
  }
  const validate = () => {
    console.log('validate parent email:',parentEmail.length);
    console.log('validate parent name:',parentName.length);
    console.log('validate parent number:',parentNumber.length);
    return parentEmail.length & parentName.length & parentNumber.length;
  };
  useEffect(()=>{
    const getUsers = async () => {
      const data = await getDocs(usersCollectionRef);
      console.log(data);
      setUsersData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      const tempData=data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      const filterData=tempData.filter((item)=>item.id===props.userId)
      console.log("filter data:",filterData);
      var sid=filterData[0].id;
      setId(filterData[0].id);
      console.log("id:",sid);
      };
      getUsers();
      const isValid = validate();
      setValid(isValid);
  },[parentEmail,parentName,parentNumber])
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className={`${styles["input-grp"]}  position-absolute border-0 end-0`}
      ></Modal.Header>
      <Form className={`${styles["input-grp"]} p-5`} closeButton>
        <Form.Group
          className={`${styles["input-grp"]}`}
          controlId="formBasicEmail"
        >
          <Form.Label></Form.Label>
          <Form.Control type="email" name="parentemail" placeholder="Enter Parent's Email ID" onChange={(event)=>handleChange(event)}/>
        </Form.Group>
        <p style={{color:'red'}}>{emailValidate}</p>
        <Form.Group
          className={`${styles["input-grp"]}`}
          controlId="formBasicPassword"
        >
          <Form.Label></Form.Label>
          <Form.Control type="text" name="parentname" placeholder="Enter Parent's Name" onChange={(event)=>handleChange(event)}/>
        </Form.Group>
        <p style={{color:'red'}}>{nameError}</p>
        <Form.Group
          className={`${styles["input-grp"]} mb-3`}
          controlId="formBasicPassword"
        >
          <Form.Label></Form.Label>
          <Form.Control type="tel" name="parentnumber" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" placeholder="Enter Parent's Phone Number" onChange={(event)=>handleChange(event)}/>
        </Form.Group>
        <p style={{color:'red'}}>{numberError}</p>
        <Button
          variant="primary"
          className={`w-100 font-weight-bold py-2 ${styles["submit-btn"]}`}
          onClick={updateDetails}
          disabled={!isValid}
        >
          confirm & Proceed
        </Button>
      </Form>
    </Modal>
  );
}

function Parentdetail({userId}) {
  console.log("userId: " + userId);
  const [modalShow, setModalShow] = React.useState(false);
  const usersCollectionRef = collection(db, "users");

  useEffect(()=>{
    const getUsers = async () => {
      const data = await getDocs(usersCollectionRef);
      console.log(data);
      const tempData=data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      const filterData=tempData.filter((item)=>item.id===userId)
      console.log("parent filter: ",filterData);
      if(filterData[0].parentEmail===''){
        setModalShow(true)
      }
      else{
        setModalShow(false)
      }
      console.log("filter data:",filterData);
      };
      getUsers();
  },[])
  return (
    <>
      <MyVerticallyCenteredModal
        userId={userId}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}


export default Parentdetail;